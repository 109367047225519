import React, { useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import { ARCHIVE_SUSPENSION_CODE } from 'src/components/add-suspension-point/violation-queries';
import { GET_SUSPENSION_CODE, UPDATE_SUSPENSION_CODE } from 'src/components/players/players-queries';
import Spinner from 'src/components/spinner/spinner';
import SuspensionCodeForm from 'src/components/suspension-code-form/suspension-code-form';
import { NavigationState } from 'src/utils/typedefs/navigation';

interface Props {
  id?: string;
}

const EditSuspensionCode: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState<any>(undefined);

  const { loading: loadingCode, data: codeData } = useQuery(GET_SUSPENSION_CODE, {
    client: meshGatewayClient,
    fetchPolicy: 'no-cache',
    variables: { code: id },
  });

  useEffect(() => {
    const newInitialValues = codeData && {
      codeName: codeData?.getSuspensionCode?.code,
      type: t('point'),
      points: codeData?.getSuspensionCode?.points,
      duration: '',
      shortDescription: codeData?.getSuspensionCode?.shortDescription,
      longDescription: codeData?.getSuspensionCode?.longDescription,
      durationFormat: '',
    };
    setInitialValues(newInitialValues);
  }, [loadingCode, codeData, t]);

  const [updateSuspensionCode, { loading, error }] = useMutation(UPDATE_SUSPENSION_CODE, {
    client: meshGatewayClient,
  });

  const [archiveCode, { loading: loadingArchive }] = useMutation(ARCHIVE_SUSPENSION_CODE, {
    client: meshGatewayClient,
  });

  const handleCancel = () => {
    navigate('/suspension/codes');
  };

  const handleSubmit = (values) => {
    updateSuspensionCode({
      variables: {
        code: id,
        input: {
          code: values?.codeName,
          longDescription: values?.longDescription,
          shortDescription: values?.shortDescription,
          points: parseInt(values?.points),
        },
      },
      onCompleted: () => {
        const state: NavigationState = {
          popup: {
            message: t('code updated'),
          },
        };
        navigate('/suspension/codes', { state });
      },
    });
  };

  const handleArchive = async () => {
    await archiveCode({
      variables: {
        code: id,
      },
      onCompleted: () => {
        const state: NavigationState = {
          popup: {
            message: t('code archived'),
          },
        };
        navigate(`/suspension/codes`, { state });
      },
    });
  };

  return initialValues ? (
    <SuspensionCodeForm
      loading={loading}
      title={t('edit code')}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      initialValues={initialValues}
      error={error}
      archive={handleArchive}
      loadingArchive={loadingArchive}
    />
  ) : (
    <Spinner />
  );
};

export default EditSuspensionCode;
