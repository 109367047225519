import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import EditSuspensionCode from 'src/components/edit-suspension-code/edit-suspension-code';
import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';

const EditSuspensionCodePage = () => {
  return (
    <Router basepath="/suspension/codes">
      <SuspensionCodeEditRoute path="/edit/:id" />
    </Router>
  );
};

interface Props extends RouteComponentProps {
  id?: string;
}

const SuspensionCodeEditRoute: React.FC<Props> = ({ id }) => {
  return (
    <Layout>
      <SEO title="Edit suspension code" />
      <PrivateRoute>
        <EditSuspensionCode id={id} />
      </PrivateRoute>
    </Layout>
  );
};

export default EditSuspensionCodePage;
